import React from "react";
import { Col, Grid, Row, Tag } from "rsuite";
import { isMobile } from "react-device-detect";
export default function HeaderLarge({ title, tag, tag2, marked, description }) {
  return (
    <>
        <Grid>
          <Row>
            <Col style={{ padding: isMobile ? "40px 20px" : "80px 0px" }}>
              <h1 style={{ color: "#000" }}>
                {title}
                {marked && <mark>{marked}</mark>}
                {tag && (
                  <>
                    <br />
                    <Tag size="lg">{tag}</Tag>
                  </>
                )}
                {tag2 && (
                  <>
                    <Tag size="md">{tag2}</Tag>
                  </>
                )}
              </h1>
              <p style={{ fontSize: "18px" }}>{description}</p>
            </Col>
          </Row>
        </Grid>
    </>
  );
}
