import * as React from "react";
import LayoutMain from "components/layout/main";
import HeaderLarge from "components/section/headerLarge";
import SectionWrapper from "components/section/sectionWrapper";
import { navigate } from "gatsby";
import { Grid, Row, Col, Panel, Tag, Stack, Divider } from "rsuite";

const ArtikelenPage = () => {
  return (
    <LayoutMain>
      <HeaderLarge
        title="Uitgelicht"
      />

      <SectionWrapper padded background>
        <Grid>
          <Row style={{ marginBottom: "20px" }}>
            <Col>
              <h3
                id="e-learning"
                style={{ color: "#000", marginBottom: "20px" }}
              >
                Artikelen
              </h3>
            </Col>
          </Row>
          <Row>
          <Col xs={24} md={12}>
              <Panel
                header="Antistollingszorg in Nederland steeds veiliger"
                bordered
                style={{
                  backgroundColor: "white",
                  cursor: "pointer",
                  marginBottom: "20px",
                }}
                onClick={() =>
                  navigate("/professional/uitgelicht/artikelen/antistollingszorg-in-nederland-steeds-veiliger/")
                }
              >
                <p>
                  In een artikel op Skipr wordt nader toegelicht dat de antistollingszorg in Nederland steeds veiliger wordt, maar dat er nog voldoende verbeterpunten zijn. Dat concludeert Marco Moesker (Nivel) in zijn proefschrift waarop hij onlangs promoveerde aan de Vrije Universiteit van Amsterdam.
                </p>
                <Stack
                  style={{ marginTop: "20px" }}
                  divider={<Divider vertical />}
                >
                  <>door: Maike van Leeuwen</>
                  <Tag>Artikelen</Tag>
                </Stack>
              </Panel>
            </Col>
            <Col xs={24} md={12}>
              <Panel
                header="We zijn live!"
                bordered
                style={{
                  backgroundColor: "white",
                  cursor: "pointer",
                  marginBottom: "20px",
                }}
                onClick={() =>
                  navigate("/professional/uitgelicht/artikelen/we-zijn-live/")
                }
              >
                <p>
                  Kennisplatform Antistolling brengt informatie en initiatieven samen. Meer dan 1 miljoen mensen in Nederland gebruiken enige vorm
                  van antistollingsmiddelen, zoals directe anticoagulantia, vitamine K antagonisten
                  en bloedplaatjesremmers. De zorg rondom deze medicatie is
                  complex en de toepassing ervan luistert zeer nauw. Daarnaast
                  zijn er veel professionals uit zowel ...
                </p>
                <Stack
                  style={{ marginTop: "20px" }}
                  divider={<Divider vertical />}
                >
                  <>door: Menno Huisman</>
                  <Tag>Artikelen</Tag>
                </Stack>
              </Panel>
            </Col>
          </Row>
        </Grid>
      </SectionWrapper>
      <SectionWrapper padded>
        <Grid>
          <Row style={{ marginBottom: "20px" }}>
            <Col>
              <h3
                id="e-learning"
                style={{ color: "#000", marginBottom: "20px" }}
              >
                Uitgelichte bronnen
              </h3>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={12}>
              <Panel
                header="Over de LTA Antistollingszorg"
                bordered
                style={{ backgroundColor: "white", cursor: "pointer" }}
                onClick={() =>
                  navigate(
                    "/professional/uitgelicht/uitgelichte-bronnen/over-de-lta-antistollingszorg/"
                  )
                }
              >
                <p>
                  De landelijke transmurale afspraak (LTA) antistollingszorg is
                  een praktische leidraad met informatie over het voorschrijven
                  en de follow-up van antitrombotica, periprocedureel beleid en
                  het beleid bij bloedingen. Ook vind je op deze website
                  informatie over communicatie met de patiënt. De
                  LTA-antistollingszorg is bedoeld voor artsen, tandartsen of
                  verpleegkundigen die te maken hebben met patiënten die
                  antitrombotica voorgeschreven krijgen.
                </p>
                <Stack
                  style={{ marginTop: "20px" }}
                  divider={<Divider vertical />}
                >
                  <>door: Laura Faber & Menno Huisman</>
                  <Tag color="primary">Uitgelichte bron</Tag>
                </Stack>
              </Panel>
            </Col>
          </Row>
        </Grid>
      </SectionWrapper>
    </LayoutMain>
  );
};

export default ArtikelenPage;
